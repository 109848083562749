import ExtendableError from 'utils/ExtendableError';

export default class AxiosError extends ExtendableError {
  constructor(error) {
    super(error.message);
    this.stack = error.stack;
    this.status = error.response?.status;
    this.statusText = error.response?.statusText;
    this.response = error.response;
  }
}
